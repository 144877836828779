import { useEffect, useState } from 'react';
import api from '../../services/api';
import StyledTable from '../utils/StyledTable';
import ImageAvatar from '../utils/ImageAvatar';
import Deactivate from '../utils/forms/Deactivate';
import MenuTable from '../utils/MenuTable';
import { getImageSize } from '../utils/image.utils';

const Table = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [limit, setLimit] = useState(50);
  const columns = [
    {
      name: 'Imagen',
      cell: (row) => <ImageAvatar src={getImageSize(row.image, 'thumbnail')} />,
      maxWidth: '100px',
    },
    {
      name: 'Nombre',
      selector: (row) => row.name.es,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Descripción',
      selector: (row) => row.description.es,
      sortable: true,
      maxWidth: '600px',
      grow: 3,
    },
    {
      name: 'Estado',
      cell: (row) => {
        return (
          <Deactivate
            id={row.slug}
            name={row.name}
            valor={row.status}
            url='/categories/status-change/'
          />
        );
      },
      maxWidth: '100px',
      grow: 1,
    },
    {
      name: 'Opciones',
      cell: (row) => {
        return <MenuTable id={row.slug} edit={true} />;
      },
      right: true,
      maxWidth: '100px',
      grow: 1,
    },
  ];
  const fetchData = async (page) => {
    setLoading(true);
    const response = await api.get(`/categories?page=${page}&limit=${limit}`);
    setData(response.docs);
    setTotalDocs(response.totalDocs);
    setLoading(false);
  };
  const handlePageChange = (page) => {
    fetchData(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await api.get(`/categories?page=${page}&limit=${newPerPage}`);
    setData(response.docs);
    setLimit(newPerPage);
    setLoading(false);
  };
  useEffect(() => {
    fetchData(1);
  }, []);
  return (
    <StyledTable
      data={data}
      columns={columns}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalDocs}
      paginationPerPage={limit}
      paginationRowsPerPageOptions={[50, 100, 150, 200]}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      noDataComponent={'No hay categorías'}
    />
  );
};
export default Table;
