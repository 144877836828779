import { useEffect, useState } from 'react';
import api from '../../services/api';
import StyledTable from '../utils/StyledTable';
import ImageAvatar from '../utils/ImageAvatar';
import Deactivate from '../utils/forms/Deactivate';
import MenuTable from '../utils/MenuTable';
import styled from 'styled-components';
import { getImageSize } from '../utils/image.utils';

const Table = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [limit, setLimit] = useState(50);
  const [providers, setProviders] = useState([]);
  const [searching, setSearching] = useState(false);
  const columns = [
    {
      name: 'Imagen',
      cell: (row) => <ImageAvatar src={getImageSize(row.image, 'thumbnail')} />,
      maxWidth: '100px',
    },
    {
      name: 'Nombre',
      selector: (row) => row.firstName,
      sortable: true,
      maxWidth: '600px',
      grow: 3,
    },
    {
      name: 'Apellidos',
      selector: (row) => row.paternalSurname,
      sortable: true,
      maxWidth: '600px',
      grow: 3,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      maxWidth: '600px',
      grow: 3,
    },
    {
      name: 'Ciudad',
      selector: (row) => row.city,
      sortable: true,
      maxWidth: '600px',
      grow: 3,
    },
    {
      name: 'Estado',
      cell: (row) => {
        return (
          <Deactivate
            id={row._id}
            name={row.name}
            valor={row.status}
            url='/providers/status-change/'
          />
        );
      },
      maxWidth: '100px',
      grow: 1,
    },
    {
      name: 'Opciones',
      cell: (row) => {
        return <MenuTable id={row._id} edit={true} />;
      },
      right: true,
      maxWidth: '100px',
      grow: 1,
    },
  ];
  const fetchData = async (page) => {
    setLoading(true);
    const response = await api.get(`/providers?page=${page}&limit=${limit}`);
    setData(response.docs);
    setTotalDocs(response.totalDocs);
    setLoading(false);
  };
  const handlePageChange = (page) => {
    fetchData(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await api.get(`/providers?page=${page}&limit=${newPerPage}`);
    setData(response.docs);
    setLimit(newPerPage);
    setLoading(false);
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const searchProvider = async (e) => {
    if (e.length > 3) {
      setLoading(true);
      setSearching(true);
      const resProviders = await api.get(`/providers?search=${e}`);
      setProviders(resProviders.docs);
      setLoading(false);
    } else {
      setSearching(false);
    }
  };
  return (
    <>
      <MyStyledInput
        type='text'
        placeholder='Búsqueda'
        onChange={(e) => searchProvider(e.target.value)}
      />
      <StyledTable
        data={searching ? providers : data}
        columns={columns}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalDocs}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[50, 100, 150, 200]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        noDataComponent={'No hay proveedores'}
      />
    </>
  );
};
export default Table;

const MyStyledInput = styled.input`
  padding: 1em 0.8em;
  width: 100%;
  display: block;
  margin-bottom: 1em;
  background: none;
  border: 1px solid ${(props) => props.theme.separatorColor};
  border-radius: 5px;
  outline: none;
  color: ${(props) => props.theme.fontColor};
  &:-webkit-autofill {
    appearance: inherit;
    background-color: inherit !important;
    background-image: none !important;
    color: inherit !important;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.separatorColor};
    opacity: 1; /* Firefox */
  }
`;
